import React from "react"
import Layout from "../components/common/layout"
import Meta from "../components/common/meta"
import { Link } from "gatsby"
import PageTitle from "../components/pageTitle/pageTitle"
// import MainVisual from "../components/pages/about/MainVisual";
import MemberList from "../components/pages/about/MemberList"
import gsap from "gsap"

class aboutPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toggle: {
        ceo: false,
        spain: false,
      },
    }

    this.commentToggle = this.commentToggle.bind(this)
  }

  commentToggle = tg => {
    const trigger = tg.currentTarget
    const type = tg.currentTarget.dataset.toggleTrigger
    const target = document.querySelector(`[data-toggle-target=${type}]`)
    // target.classList.toggle("is-active");

    gsap.killTweensOf(target)
    if (this.state.toggle[type] === false) {
      this.state.toggle[type] = true

      gsap.to(trigger, {
        duration: 0.3,
        rotateX: 180,
        ease: "power1.in",
      })
      gsap.to(target, {
        duration: 0.3,
        height: "auto",
        ease: "power1.in",
      })
    } else {
      this.state.toggle[type] = false

      gsap.to(trigger, {
        duration: 0.45,
        rotateX: 0,
        ease: "power2.inOut",
      })
      gsap.to(target, {
        duration: 0.45,
        height: 0,
        ease: "power2.inOut",
      })
    }
  }

  render() {
    return (
      <Layout page="about">
        <Meta
          title="about us | 1moku co."
          description="hirofumi sugaを中心に庭園デザインをはじめ、庭師の視点でとらえた空間デザイン、ランドスケープデザインなどを手掛けている1moku co.についてご紹介します。"
          url="https://www.1moku.co.jp/about/"
          type="article"
        />
        <div className="about-container">
          <PageTitle title="about us" />
          <div className="about-main">
            <section className="intro">
              <div className="inner">
                <div className="intro__container">
                  <h1 className="intro__title" data-scroll="">
                    <span className="intro__title-main en">About us</span>
                    <span className="intro__title-sub">
                      <span className="en">1moku</span>
                      <span className="ja">について</span>
                    </span>
                  </h1>
                  <div className="intro__contents pc">
                    <p className="intro__text en" data-scroll="">
                      Nestled in the heart of Jingumae, Tokyo, our firm takes
                      pride in being a global design powerhouse. Our reach
                      extends across continents, bringing our expertise to
                      Japan, Asia, the Middle East, and Europe. At our core, we
                      are more than just a design firm - we are curators of
                      landscapes, architects of atmospheres, and creators of
                      experiences. With a dedicated design office in the
                      artistic heart of Barcelona, we have established ourselves
                      as a versatile entity, specializing not only in landscape
                      design but also in scenography, garden design,
                      landscaping, exterior work, and civil engineering. Our
                      journey is defined by the fusion of traditional and modern
                      Japanese garden design, elevated by a thoughtful
                      integration of Western philosophies. This dynamic synergy
                      is made possible by our diverse team of English, Japanese,
                      and Spanish trilingual professionals, each bringing a
                      unique perspective to the table. This multicultural
                      approach allows us to transcend boundaries and craft
                      designs that resonate on a global scale. In our commitment
                      to excellence, we go beyond aesthetics. We believe in
                      responsible design practices, acknowledging the importance
                      of biodiversity, the utilization of local plants, and
                      thorough research of the local environment. Our designs
                      are not just visually striking; they are an immersive
                      journey into sustainability and harmony. We understand
                      that a well-designed space goes beyond its visual appeal.
                      It should also contribute positively to the well-being of
                      individuals. With this in mind, we prioritize the healing
                      effects of our designs on humans, ensuring that each
                      project we undertake brings not only visual delight but
                      also a sense of tranquillity and rejuvenation. Welcome to
                      our world - where design knows no borders, and every
                      project tells a story of cultural integration,
                      sustainability, and unparalleled beauty.
                    </p>
                    <p className="intro__text ja" data-scroll="">
                      1moku
                      co.は、東京・神宮前を拠点に、バルセロナにデザインオフィスを構え、日本・アジア・中東・ヨーロッパと国際的に活動し、ランドスケープの設計・デザインだけにとどまらず、空間演出、庭園設計、造園・外構工事、土木工事まで手掛けるオールマイティなデザインスタジオである。伝統的なものからモダンな日本庭園の設計に卓越していると同時に、英語・日本語・スペイン語のトリリンガルなグローバルチームによって、西洋的な哲学もデザインに取り入れている。日本独自の美意識とグローバルなビジョンを融合することで質の高いデザインの可能性を追求し続けている。1mokuの卓越性は美学を超越しているためにある。それは、生物多様性の重要性を認識し、その土地の植物を活用し、環境を徹底的に調査する、責任ある設計手法を信条とし、見た目の美しさだけでなく、持続可能性との調和を追求する旅でもある。よくデザインされた空間は、視覚的な魅力にとどまらない。人々へ癒しを与えることが第一に考えられ、個人の幸福に貢献するものだと保証されている。私たちは、各プロジェクトが視覚的な歓びだけでなく、安らぎと活力をもたらすことを保証する。デザインに国境はなく、すべてのプロジェクトが文化の融合、持続可能性、比類ない美しさを物語るという理念を軸に活動している。
                    </p>
                  </div>
                  <div className="intro__contents sp">
                    <p className="intro__text en" data-scroll="">
                      Nestled in the heart of Jingumae, Tokyo, our firm takes
                      pride in being a global design powerhouse. Our reach
                      extends across continents, bringing our expertise to
                      Japan, Asia, the Middle East, and Europe. At our core, we
                      are more than just a design firm – we are curators of
                      landscapes, architects of atmospheres, and creators of
                      experiences. With a dedicated design office in the
                      artistic heart of Barcelona, we have established ourselves
                      as a versatile entity, specializing not only in landscape
                      design but also in scenography, garden design,
                      landscaping, exterior work, and civil engineering.
                    </p>
                    <p className="intro__text ja" data-scroll="">
                      1Moku.coは、東京・神宮前を拠点に、バルセロナにデザインオフィスを構え、日本・アジア・中東・ヨーロッパと国際的に活動し、ランドスケープの設計・デザインだけにとどまらず、空間演出、庭園設計、造園・外構工事、土木工事まで手掛けるオールマイティなデザイン事務所である。伝統的からモダンな日本庭園の設計に卓越していると同時に、英語・日本語・スペイン語のトリリンガルのグローバルチームによって、西洋的な哲学もデザインに取り入れ、日本独自の美意識とグローバルなビジョンを融合することで質の高いデザインの可能性を追求し続けている。
                    </p>
                  </div>
                </div>
              </div>
            </section>
            {/* //.intro */}

            <section className="ceo">
              <div className="inner">
                <div className="ceo__container">
                  <div className="ceo__profile profile">
                    <div className="profile__img" data-scroll="">
                      <img
                        className="pc"
                        src="/image/about/top/profile.jpg"
                        alt=""
                        width="420"
                        height="540"
                      />
                      <img
                        className="sp"
                        src="/image/about/top/sp_profile.jpg"
                        alt=""
                        width="420"
                        height="540"
                      />
                    </div>
                    <div className="profile__text" data-scroll="">
                      <h2 className="profile__name">
                        <span className="en">hirofumi suga</span>
                        <span className="ja">スガ ヒロフミ</span>
                      </h2>
                      <div className="profile__position">artist / CEO</div>
                    </div>
                    <button
                      className="profile__trigger"
                      onClick={this.commentToggle}
                      data-toggle-trigger="ceo"
                    ></button>
                  </div>
                  <div className="ceo__contents" data-toggle-target="ceo">
                    <p className="ceo__name en">
                      <span className="ceo__name-text en">hirofumi suga</span>
                      <span className="ceo__name-text ja">スガ ヒロフミ</span>
                    </p>
                    <p className="ceo__text en">
                      Born in 1972 in Shakujii Park, Tokyo, Hirofumi Suga began
                      his journey in landscape architecture at the age of 17. A
                      year and a half later, after being enthralled by the
                      beauty of Kyoto's gardens he sought to receive training by
                      the head garden designer at Ryoanji, only to be refused by
                      the chief priest. By introduction from Ryoanji's head
                      garden designer, he was able to receive training at
                      Kyoto/Jonangu Shrine famous for Kyokusui no En. Following
                      roughly four years of training, Suga began his own career
                      as a garden designer at the age of 22. From that time, he
                      also began studying the art of tea ceremony and flower
                      arrangement. Around the age of 30, design and planning job
                      requests began coming in more often, and at the age of 44
                      he opened up his office in Barcelona, Spain. Currently, he
                      works globally based out of both Japan and Barcelona.
                    </p>
                    <p className="ceo__text ja">
                      1972年東京都石神井公園生まれ。17歳から造園の道に入る。1年半後、京都の庭の美しさに魅了され、龍安寺の庭師の親方に修行のお願いをするが住職に断られ、見かねた親方の紹介で、曲水の宴で有名な京都/城南宮庭園へ修行入りする。その後、約4年間の修行を経て、22歳に庭師として独立。その頃から茶道と花道に触れる。30歳を越してからデザイン・設計の依頼が増え始め、44歳にはスペイン/バルセロナにオフィスを開設。現在では、日本、バルセロナを拠点に国際的に活動する。
                    </p>
                    <div className="ceo_sns" data-scroll="">
                      <a
                        className="ceo_sns-link"
                        target="_blank"
                        href="https://www.instagram.com/hirofumi.suga1972/"
                        rel="noreferrer noopener"
                      >
                        <span className="icon-instagram ceo_sns-icon"></span>
                        <span className="ceo_sns-text">hirofumi.suga1972</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* //.ceo */}

            <section className="spain">
              <div className="inner">
                <div className="spain__container">
                  <div className="spain__profile profile">
                    <div className="profile__img --team" data-scroll="">
                      <img
                        className="pc"
                        src="/image/about/top/spain.jpg"
                        alt=""
                        width="420"
                        height="540"
                      />
                      <img
                        className="sp"
                        src="/image/about/top/sp_spain.jpg"
                        alt=""
                        width="420"
                        height="540"
                      />
                    </div>
                    <div className="profile__text" data-scroll="">
                      <h2 className="profile__name">
                        <span className="en">spain team</span>
                        <span className="ja">スペインチーム</span>
                      </h2>
                    </div>
                    <button
                      className="profile__trigger"
                      onClick={this.commentToggle}
                      data-toggle-trigger="spain"
                    ></button>
                  </div>
                  <div className="spain__contents" data-toggle-target="spain">
                    <p className="spain__name en">
                      <span className="spain__name-text en">spain team</span>
                      <span className="spain__name-text ja">
                        スペインチーム
                      </span>
                    </p>
                    <p className="spain__text en">
                      In the heart of Spain, our Barcelona team is the driving
                      force behind our global design initiatives. We take pride
                      in our trilingual communication abilities, seamlessly
                      navigating in Spanish, English, and Japanese. This
                      linguistic diversity is not just a practical advantage but
                      a testament to our commitment to effective cross-cultural
                      collaboration. Meet our core team members, Samuel, Tomeu,
                      and Yasmin, who infuse their distinctive talents into
                      every project.
                    </p>
                    <p className="spain__text ja">
                      スペイン・バルセロナを拠点とし、グローバルなデザインイニシアチブをとる。スペイン語、英語、日本語の3ヶ国語のコミュニケーションで、実用的な利点だけでなく、1mokuに異文化との効果的な相互作用をもたらしている。現在は、3人のコアメンバーがそれぞれ才能を発揮し、あらゆるプロジェクトに取り組んでいる。
                    </p>
                  </div>
                </div>
              </div>
            </section>
            {/* //.spain */}

            <section className="member">
              <div className="inner">
                <MemberList />
              </div>
            </section>
            {/* //.member */}

            <section className="info">
              <div className="inner">
                <div className="info__container">
                  <div className="info__block" data-scroll="">
                    <div className="info__unit">
                      <p className="info__title">niwa-shi</p>
                      <ul className="info__list">
                        <li className="info__item">naoshi tanaka</li>
                        <li className="info__item">yusuke nakayama</li>
                        <li className="info__item">koichi mitani</li>
                      </ul>
                      <span className="info__more">and more...</span>
                    </div>
                  </div>
                  <div className="info__block" data-scroll="">
                    <div className="info__unit">
                      <p className="info__title">service</p>
                      <ul className="info__list info__list--service">
                        <li className="info__item">garden design</li>
                        <li className="info__item">
                          garden landscaping &amp;
                          <br />
                          outdoor construction
                        </li>
                        <li className="info__item">
                          architectural design &amp;
                          <br />
                          construction
                        </li>
                        <li className="info__item">space production</li>
                        <li className="info__item">management</li>
                        <li className="info__item">other services</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* //.info */}

            <div className="related">
              <ul className="related__list">
                <li className="related__item" data-scroll="">
                  <Link className="related__link" to="/company">
                    company guide<span className="related__arrow">more</span>
                  </Link>
                </li>
                <li className="related__item" data-scroll="">
                  <Link className="related__link" to="/recruit">
                    recruitment<span className="related__arrow">more</span>
                  </Link>
                </li>
                <li className="related__item" data-scroll="">
                  <Link className="related__link" to="/media">
                    media<span className="related__arrow">more</span>
                  </Link>
                </li>
                <li className="related__item" data-scroll="">
                  <Link className="related__link" to="/exhibition">
                    exhibition<span className="related__arrow">more</span>
                  </Link>
                </li>
                <li className="related__item" data-scroll="">
                  <Link className="related__link" to="/works">
                    works<span className="related__arrow">more</span>
                  </Link>
                </li>
              </ul>
            </div>
            {/* //.related */}
          </div>
        </div>
      </Layout>
    )
  }
}

export default aboutPage
